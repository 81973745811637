<template>
  <div class="">
    <div v-if="width != 'sm'" class="row res-card shadow-eff" :class="[
      ar ? 'flex-row-reverse' : 'flex-row',
      width == 'sm' ? 'px-1' : '',
    ]">
      <div class="col-md-12 px-0 col-lg-4 col-xl-3 res-header" v-if="item">
        <div class="res-image" @click="goProfile(item.buisness.id)" :class="
          item.buisness.media.length && item.buisness.media[0].url
            ? ''
            : 'no-img'
        ">
          <img :src="
            item.buisness.media.length
              ? item.buisness.media[0].url
              : placeHolderImg
          " />
          <div v-if="item.buisness.is_open_now" class="badge-pri fly mx-1 mt-1" :class="ar ? 'f-right' : 'f-left'"> {{
              $t("open")
          }} </div>
          <div v-else class="badge-pri fly place-close mx-1 mt-1" :class="ar ? 'f-right' : 'f-left'"> {{ $t("close") }}
          </div>
          <!-- <div class="item-address">
            <button class="btn-pri text-ellipsis"> {{item.buisness.area}} </button>
          </div> -->
        </div>
      </div>
      <div class="col-md-12 px-0 col-lg-8 col-xl-9 res-details px-md-3 pb-0">
        <div class="row px-1">
          <div class="col-12 px-0 mt-sm-3 mt-md-2 mt-lg-2">
            <div class="res-name pointer" :class="ar ? 'f-right' : 'f-left'" @click="goProfile(item.buisness.id)"> {{
                item.name
            }} </div>
            <div class="res-btns" :class="ar ? 'f-left' : 'f-right'">
              <button class="main-fav-btn in-result has-text" @click="goMap(item.buisness.location.address_link)"
                :disabled="item.buisness.location.address_link ? false : true">
                <i class="fa fa-map mx-1"></i> {{ $t("directions") }} </button>
              <button class="main-fav-btn mx-1 in-result" @click="goPhone(item.buisness.location.phone)"
                :disabled="item.buisness.location.phone ? false : true">
                <i class="fa fa-phone"></i>
              </button>
              <button class="main-fav-btn in-result" v-if="loggedIn" :class="ar ? 'f-left' : 'f-right'" @click="
                favourited ? favouriteDelete() : favouriteAdd(item.buisness)
              ">
                <i class="fa" :class="favourited ? 'fa-heart' : 'fa-heart-o'"></i>
              </button>
            </div>
          </div>
          <div class="col-12 px-0">
            <div :class="ar ? 'text-right' : 'text-left'" class="res-categories  mouse"> {{
                htmlDecode(item.buisness.category.name)
            }} </div>
          </div>
          <div class="col-12 px-0">
            <div :class="ar ? 'justify-content-end' : 'text-left'" class="d-flex">
              <Stars :stars="item.buisness.rate" class="col-xs-6 pa-0" />
              <div class="col-xs-5 px-5" v-show="item.buisness.shipping_methods">
                <span class="del-badge-pri ">
                  <i class="fa fa-check-circle"></i> {{ $t("delivery") }} </span>
                <span class="del-badge-pri">
                  <i class="fa fa-check-circle"></i> {{ $t("takeAway") }} </span>
              </div>
            </div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="">
          <div class="res-desc col-12 px-0 d-flex" :class="ar ? 'justify-content-end' : 'justify-content-start'"
            v-html="removeBrReapeted(item.buisness.description)">
            <!-- {{item.buisness}} -->
          </div>
          <div class="res-footer col-12 px-0 d-flex" :class="ar ? 'text-right' : 'text-left'">
            <button class="btn-pri my-1" v-if="item.buisness.allow_checkin"
              @click="goCheckin(item.buisness.id, 'checkin')">
              <!-- <i class="fa fa-map"></i> --> {{ $t("Checkin") }}
            </button>
            <button class="btn-pri my-1" v-if="
              item.buisness.style_id
                ? item.buisness.style_id == 3
                : item.buisness.show_prices == 'Yes'
            " @click="goProfile(item.buisness.id, 3)">
              <!-- <i class="fa fa-map"></i> --> {{ $t("startOrder") }}
            </button>
            <button class="btn-pri my-1" v-if="item.buisness.allow_booking" @click="goReserve(item.buisness.id)">
              <!-- <i class="fa fa-map"></i> --> {{ $t("Reserve") }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row res-card shadow-eff p-2" :class="ar ? 'flex-row-reverse' : ''">
      <div class="col-6 px-0">
        <div class="res-image-sm" @click="goProfile(item.buisness.id)" :class="
          item.buisness.media.length && item.buisness.media[0].url
            ? ''
            : 'no-img'
        ">
          <img :src="
            item.buisness.media.length
              ? item.buisness.media[0].url
              : placeHolderImg
          " />
          <div v-if="item.buisness.is_open_now" class="badge-pri fly mt-1 mx-1" :class="ar ? 'f-right' : 'f-left'"> {{
              $t("open")
          }} </div>
          <div v-else class="badge-pri fly place-close mt-1 mx-1" :class="ar ? 'f-right' : 'f-left'"> {{ $t("close") }}
          </div>
        </div>
      </div>
      <div class="col-6 d-flex align-items-start px-0"
        :class="$i18n.locale == 'ar' ? 'justify-content-start' : 'justify-content-end'">
        <button class="main-fav-btn in-result" @click="goMap(item.buisness.location.address_link)"
          :disabled="item.buisness.location.address_link ? false : true">
          <span class="icon">
            <i class="fa fa-map"></i>
          </span>
        </button>
        <button class="main-fav-btn in-result mx-1" @click="goPhone(item.buisness.location.phone)"
          :disabled="item.buisness.location.phone ? false : true">
          <span class="icon">
            <i class="fa fa-phone"></i>
          </span>
        </button> <button class="main-fav-btn in-result" v-if="loggedIn" :class="ar ? 'f-left' : 'f-right'" @click="
          favourited ? favouriteDelete() : favouriteAdd(item.buisness)
        ">
          <i class="fa" :class="favourited ? 'fa-heart' : 'fa-heart-o'"></i>
        </button>
      </div>
      <div class="col-12 res-details readmore px-0 px-md-3 pb-0">
        <div class="w-80 res-name-sm mt-n3" :class="ar ? 'f-right' : 'f-left'" @click="goProfile(item.buisness.id)"> {{
            item.name
        }} </div>
      </div>
      <div class="col-12 px-0">
        <div :class="ar ? 'text-right' : 'text-left'" class="res-categories  mouse"
          v-html="item.buisness.category.name"></div>
      </div>
      <div class="res-footer col-12 px-0" :class="ar ? 'text-right' : 'text-left'">
        <button class="btn-pri my-1 mx-auto" v-if="item.buisness.allow_checkin"
          :class="width == 'sm' ? 'px-3 mx-2' : ''" @click="goCheckin(item.buisness.id, 'checkin')"> {{ $t("Checkin") }}
        </button>
        <button class="btn-pri my-1 mx-0" v-if="
          item.buisness.style_id
            ? item.buisness.style_id == 3
            : item.buisness.show_prices == 'Yes'
        " :class="width == 'sm' ? 'px-3 mx-2' : ''" @click="goProfile(item.buisness.id, 3)"> {{ $t("startOrder") }}
        </button>
        <button class="btn-pri my-1 mx-0" :class="width == 'sm' ? 'px-3 mx-2' : ''" v-if="item.buisness.allow_booking"
          @click="goReserve(item.buisness.id)"> {{ $t("Reserve") }} </button>
      </div>
    </div>
  </div>
</template>

<script>
import Stars from "@/components/misc/Stars";
import { helpers } from "../../Helpers";
import cookies from "vue-cookies";
import { mapActions, mapMutations } from "vuex";

export default {
  mounted() {
    this.checkFavourites(this.item.buisness);
  },
  components: {
    Stars,
  },

  data() {
    return {
      placeHolderImg: require("../../assets/images/logo-icon.png"),
      favourited: false,
      favID: "",
    };
  },
  mixins: [helpers],
  props: ["item"],
  computed: {
    ar() {
      return this.$i18n.locale == "ar";
    },
    width() {
      return this.$store.getters.width;
    },
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    favourites() {
      return this.$store.getters.favourites;
    },
  },
  watch: {
    favourites: function () {
      this.checkFavourites(this.item.buisness);
    },
  },
  methods: {
    ...mapActions(["isFavourite", "addFavourite", "deleteFavourite"]),
    ...mapMutations(["showMsg"]),
    removeBrReapeted(desc) {
      const formated = desc.split("<br>");
      return formated.join(" ").split("\r").join(" ");
    },
    goReserve(id, type) {
      this.$router.push({
        name: "Reservation",
        params: {
          id,
        },
      });
    },
    goCheckin(id, type) {
      this.$router.push({
        name: "Checkin",
        params: {
          id,
        },
      });
    },
    goProfile(id, tab = 1) {
      this.$router.push({
        name: "BusinessProfile",
        params: {
          locale: this.$i18n.locale,
          id,
          tab,
          BName: tab == 3 ? "products" : "",
        },
      });
    },
    goMap(link) {
      window.open(link, '_blank');
    },
    goPhone(link) {
      window.location.href = `tel:${link}`;
    },
    checkFavourites(item) {
      let check = this.favourites.find((el) =>
        el.business ? el.business.id == item.id : undefined
      );
      if (check) {
        this.favID = check.id;
        this.favourited = true;
      }
    },
    favouriteAdd(item) {
      this.addFavourite({
        business_id: item.id,
        api_token: cookies.get("user_token"),
      })
        .then((res) => {
          this.showMsg({
            type: "success",
            text: this.$t("AddedtoFavourites"),
          });
          this.favID = res.data.data.id;
          this.favourited = true;
          this.$forceUpdate();
        })
        .catch((err) => {
          this.showMsg({
            type: "error",
            text: this.$t("AddtoFavouritesError"),
          });
        });
    },
    favouriteDelete() {
      this.deleteFavourite({
        id: this.favID,
        api_token: cookies.get("user_token"),
      })
        .then((res) => {
          this.showMsg({
            type: "success",
            text: this.$t("FavItemDeleted"),
          });
          this.favID = "";
          this.favourited = false;
          this.$forceUpdate();
        })
        .catch((err) => {
          this.showMsg({
            type: "error",
            text: this.$t("FavItemDeletedError"),
          });
        });
    },
  },
};
</script>