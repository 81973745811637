<template>
  <div class="container">
    <div class="row">
      <div class="col-12 px-0">
        <router-view :cityId="cityId" :text="text"> </router-view>
      </div>
    </div>
    <NoPlaces class="mt-5" v-if="(searchResults.data && searchResults.data.length == 0) || loading"
      :msg="$t('noResults')" />
  </div>
</template>

<script>
import Pagination from "@/components/misc/Pagination.vue";
import SearchFilter from "@/components/forms/SearchFilter.vue";
import NoPlaces from "@/components/misc/NoPlaces.vue";
import ResultCard from "@/components/Listings/resultCard.vue";
import cookies from "vue-cookies";
import { helpers } from "../Helpers";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SearchResults",
  components: {
    SearchFilter,
    ResultCard,
    Pagination,
    NoPlaces,
  },
  mixins: [helpers],

  data() {
    return {
      text: "",
      cityId: "",
      categoryId: "",
      page: 1,
      booking: false,
    };
  },

  created() {
    this.$loadScript("../../../js/bootstrap.bundle.js");
    this.initiateParams(this.$route);
    if (this.$route.name == "SearchResults") {
      this.getSearchResults({
        categoryId: this.$route.params.categoryId
          ? this.$route.params.categoryId
          : null,
        locale: this.$i18n.locale,
        text: this.$route.query.text ? this.$route.query.text : null,
        cityId: this.$route.query.cityId ? this.$route.query.cityId : null,
      });
    }
  },

  methods: {
    ...mapActions(["getSearchResults", "getUserFavourites"]),
    initiateParams(route) {
      if (route.query.cityId) {
        this.cityId = route.query.cityId;
      }
      if (route.query.text) {
        this.text = route.query.text;
      }
    },
  },

  computed: {
    ...mapGetters([
      "searchResults",
      "allProducts",
      "cities",
      "width",
      "loading",
    ]),
    locale() {
      return this.$i18n.locale;
    },
    currentRoute() {
      if (this.$route.name == "SearchResults") return "SearchResults";
    },
  },
};
</script>
