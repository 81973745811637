<template>
  <div class="">
    <div
      class="
        row
        mt-2
        mb-2
        px-0
        d-flex
        justify-content-between
        align-items-center
      "
      v-if="!isloading"
      :class="locale == 'ar' ? 'flex-row-reverse' : 'flex-row'"
    >
      <div class="filter-header">
        {{ $t("results") }} ({{ searchResults.total }})
      </div>
      <button class="btn-trans m-0" @click="filter">
        <i class="fa fa-filter"></i>
        {{ $t("Filter") }}
      </button>
    </div>
    <NoPlaces
      class="mt-5"
      v-if="
        !isloading &&
        searchResults.data &&
        searchResults.data.length == 0 &&
        loading
      "
      :msg="$t('noResults')"
    />
    <div
      v-else-if="!isloading"
      class="row justify-content-center"
      :class="$i18n.locale == 'ar' ? 'flex-row-reverse' : ''"
    >
      <div class="col-12 px-0 col-xl-10 text-center">
        <div v-for="item in searchResults.data" :key="item.id">
          <!-- <Business :item='item' /> -->
          <ResultCard :item="item" />
        </div>
      </div>
    </div>

    <div class="separator-short"></div>
    <hr />
    <div class="col-12">
      <Pagination
        v-if="!isloading && searchResults.data && searchResults.data.length > 0"
        :currentPage="page"
        :showPage="showPage"
        :lastPage="lastPage"
      ></Pagination>
    </div>

    <div class="modal fade pb-9" tabindex="-1" id="filterModal">
      <div
        class="mx-2 modal-dialog"
        :class="width == 'sm' ? 'w-100' : ['modal-lg', 'mx-auto']"
      >
        <div class="modal-content filter-contain">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
          <div class="modal-body">
            <SearchFilter :params="{ ...$route.params, ...$route.query }" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn-pri" data-dismiss="modal">
              {{ $t("save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ResultCard from "@/components/Listings/resultCard.vue";
import NoPlaces from "@/components/misc/NoPlaces.vue";
import Pagination from "@/components/misc/Pagination.vue";
import SearchFilter from "@/components/forms/SearchFilter.vue";
import cookies from "vue-cookies";

export default {
  components: {
    ResultCard,
    NoPlaces,
    Pagination,
    SearchFilter,
  },

  created() {
    this.isloading = true;
    this.getAllCategories({
      lang: this.$i18n.locale,
      tree: 0,
    })
      .then(() => {
        this.isloading = false;
      })
      .catch(() => {
        this.isloading = false;
      });
    this.getUserFavourites({
      api_token: cookies.get("user_token"),
      locale: this.$i18n.locale,
    })
      .then(() => {
        this.isloading = false;
      })
      .catch(() => {
        this.isloading = false;
      });
    if (!this.searchResults.data) {
      if (this.$route.name == "CategoryList") {
        this.$router.push({
          name: "CategoryId",
          params: { ...this.$route.params },
          query: { ...this.$route.query },
        });
      }
    }
    if (this.$route.params.page) {
      this.page = this.$route.params.page;
    }
    if (this.$route.query.cityId || this.$route.query.text) {
      this.text = this.$route.query.text;
      this.cityId = this.$route.query.cityId;
    }
  },

  data() {
    return {
      text: null,
      cityId: null,
      categoryId: null,
      page: 1,
      booking: false,
      has_products: false,
      isloading: false,
    };
  },

  computed: {
    ...mapGetters(["searchResults", "width", "loading", "all_categories"]),
    locale() {
      return this.$i18n.locale;
    },
    lastPage() {
      if (
        this.$route.name == "SearchList" ||
        this.$route.name == "SearchResults" ||
        this.$route.name == "CategoryList"
      )
        return this.searchResults.last_page;
      else return 1;
    },
  },

  methods: {
    ...mapActions([
      "getSearchResults",
      "getUserFavourites",
      "getAllCategories",
    ]),
    filter() {
      $("#filterModal").modal("show");
    },
    showPage(page) {
      if (page != this.page) {
        this.page = page;
        this.getPageData();
      }
    },
    getPageData() {
      this.isloading = true;
      this.getSearchResults({
        text: this.$route.query.text || this.text,
        cityId: this.$route.params.cityId || this.cityId,
        categoryId: this.$route.params.categoryId || this.categoryId,
        page: this.page,
        has_products: this.has_products == true ? this.has_products : "",
        booking: this.booking == true ? this.booking : "",
        locale: this.$i18n.locale,
      })
        .then(() => {
          this.isloading = false;
        })
        .catch((err) => {
          this.isloading = false;
          console.log(err);
        });
    },
  },
};
</script>