<template>
  <div class='container-fluid'>
    <div v-if="!(['BusinessProfile', 'Home']).includes(currentRoute)" class="">
      <!--start widget-->
      <div class="block">
        <div class="widget-content">
          <form @submit.prevent='refreshSearchResult' class="form-horizontal">
            <div class="form-group">
              <div class="col-12 inputGroupContainer">
                <label class="input-label" :class="locale == 'ar' ? 'f-right' : 'f-left'"> {{ $t('PlaceName') }}
                </label>
                <input :class="t_ar" v-model="text" name="name" id="name" class="form-control text-input-bot"
                  @change='refreshSearchResult'>
              </div>
            </div>
            <div class="form-group mt-2">
              <div class="col-12 inputGroupContainer">
                <label class="input-label" :class="locale == 'ar' ? 'f-right' : 'f-left'"> {{ $t('PlaceCity') }}
                </label>
                <select :class="d_ar" v-model="cityId" name='type' id='type' class='form-control select-input-bot  p-0'
                  @change='refreshSearchResult'>
                  <option :class="f_ar" value='' disabled selected> Select City </option>
                  <option :class="f_ar" value=''> None </option>
                  <option :class="f_ar" v-for="city in cities" :key="city.id" :value='city.id'> {{ city.name }}
                  </option>
                </select>
              </div>
              <div class="col-12 inputGroupContainer">
                <label class="input-label" :class="locale == 'ar' ? 'f-right' : 'f-left'"> {{ $t('PlaceCategory') }}
                </label>
                <select :class="d_ar" v-model="params.categoryId" name='type' id='type'
                  class='form-control select-input-bot p-0' @change='refreshCategory'>
                  <option value='' disabled selected> Select Category </option>
                  <option value=''> None </option>
                  <option v-for='cat in all_categories' :key="cat.id" :value='cat.id' v-html='cat.name'></option>
                </select>
              </div>
            </div>
            <div class="col-12  my-4" :class="t_ar">
              <div class="form-group form-check">
                <label for='booking' class="form-check-label"> {{ $t('availableBookingOnly') }} </label>
                <input class="mx-2 mt-1" v-model="booking" @change='refreshSearchResult' data-toggle="toggle"
                  type="checkbox" id='booking'>
              </div>
            </div>
            <div class="col-12 my-4" :class="t_ar">
              <div class="form-group form-check">
                <label for='has_products' class="form-check-label"> {{ $t('HasProducts') }} </label>
                <input class="mx-2 mt-1" v-model="has_products" @change='refreshSearchResult' data-toggle="toggle"
                  type="checkbox" id='has_products'>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';

export default {
  mounted() {
    this.initiateParams();
  },

  props: ['params'],

  data() {
    return {
      text: '',
      cityId: '',
      categoryId: '',
      booking: false,
      has_products: false,
    }
  },

  watch: {
    query: function (newQ, oldQ) {
      this.initiateParams()
      this.getSearchResults({
        locale: this.$i18n.locale,
        text: this.text,
        cityId: this.cityId,
        categoryId: this.categoryId,
        booking: this.booking === true ? this.booking : '',
        has_products: this.has_products,
      }).then(res => this.page = 1)
        .catch(err => console.log(err));
    }
  },

  computed: {
    ...mapGetters(['cities', 'categories', 'outerCat', 'width', 'all_categories']),
    locale() {
      return this.$i18n.locale;
    },
    currentRoute() {
      return this.$route.name;
    },
    query() {
      return this.$route.query;
    },
    t_ar() {
      return this.$i18n.locale == 'ar' ? 'text-right' : 'text-left';
    },
    d_ar() {
      return this.$i18n.locale == 'ar' ? 'dir-rtl' : '';
    },
    flex_ar() {
      return this.$i18n.locale == 'ar' ? 'flex-row-reverse' : '';
    },
    f_ar() {
      return this.$i18n.locale == 'ar' ? 'f-right' : 'f-left';
    },
    f_ar_reverse() {
      return this.$i18n.locale == 'ar' ? 'f-left' : 'f-right';
    },
  },

  methods: {
    ...mapActions(['getSearchResults', 'getBusinessProducts']),
    initiateParams() {
      // console.log(this.query, this.params)
      this.text = this.query.text;
      this.cityId = this.query.cityId;
      this.categoryId = this.params.categoryId;
    },
    refreshSearchResult() {
      this.$router.push({
        name: this.$route.name,
        params: {
          ...this.$route.params
        },
        query: {
          text: this.text,
          cityId: this.cityId
        }
      })
      this.getSearchResults({
        locale: this.$i18n.locale,
        text: this.text,
        cityId: this.cityId,
        categoryId: this.params.categoryId,
        booking: this.booking === true ? this.booking : '',
        has_products: this.has_products === true ? this.has_products : ''
      }).then(res => this.page = 1)
        .catch(err => console.log(err));
    },
    refreshCategory() {
      $('.modal').modal('hide')
      this.$router.push({
        name: 'CategoryId',
        params: {
          categoryId: this.params.categoryId,
          locale: this.$i18n.locale
        },
        query: {
          text: this.text,
          cityId: this.cityId
        }
      })
      // this.refreshSearchResult();
    },
  },

  destroyed() {
    if (this.text != '') {
      localStorage.setItem('text', this.params.text || '')
    }
    if (this.cityId != '') {
      localStorage.setItem('city', this.params.cityId || '')
    }
    if (this.booking != false) {
      localStorage.setItem('book', this.params.booking || '')
    }
    if (this.has_products != false) {
      localStorage.setItem('book', this.params.has_products || '')
    }
  },
}
</script>