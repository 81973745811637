<template>
  
</template>
<script>

export default {

  created(){
    this.getAllCategories();
  },

  beforeRouteUpdate(to, from, next){
    this.getAllCategories();
    next();
  },

  methods:{
    getAllCategories() {
      this.$store.dispatch('getAllCategories', {
        lang: this.$i18n.locale,
      }).then(res => {
        this.fetchData();
      }).catch(err => console.log(err))
    },
    fetchData(){
      if(this.$route.params.categoryId == '' || this.$route.params.categoryId == undefined){
        this.$store.dispatch('getSearchResults', { ...this.$route.params, ...this.$route.query})
          .then(res => {
            this.$router.push({
            name: 'SearchResults',
            params: {
              ...this.$route.params,
              locale: this.$i18n.locale,
              page: this.$route.params.page,
            },
            query: {
              text: this.$route.query.text,
              cityId: this.$route.query.cityId
            }
          });
          })
        // console.log(this.$route)
        
      }else {
        this.$store.dispatch('getSearchResults', { ...this.$route.params, ...this.$route.query})
        .then(res => {
          setTimeout(() => {  }, 150)
          // console.log("🚀 ~ file: CategoryFetch.vue ~ line 50 ~ fetchData ~ this.allCategories", this.allCategories)
          let category = this.allCategories.find(cat => cat.id == this.$route.params.categoryId);
          this.$router.replace({
            name: 'CategoryList',
            params:{
              categoryId: this.$route.params.categoryId,
              CName: category &&  category.name ? category.name.replace(/ /g, '_').toLowerCase() : '',
              page: this.$route.params.page,
              locale: this.$i18n.locale
            },
            query: {
              text: this.$route.query.text,
              cityId: this.$route.query.cityId
            }
          });
        }).catch(err => console.log(err))
      }
    }
  },
  
  computed: {
    allCategories() {
      return this.$store.getters.all_categories;
    }
  }
}  
</script>